import React from "react";
import Logo from "../../assets/images/logos.png";
import { Link, useLocation } from "react-router-dom";

const HeaderLogo = () => {
  const location = useLocation();
  const handleLogoCick = () => {
    if (location.pathname === "/") {
      setTimeout(function () {
        window.location.reload(false);
      }, 500);
    }
  };
  return (
    <React.Fragment>
      <div className="sixai-chats-modals-header-logo">
        <Link
          to="/"
          title="By0"
          onClick={handleLogoCick}
          className="sixai-chats-modals-header-logo-text"
        >
          <img
            className="sixai-chats-modals-header-logo-img"
            src={Logo}
            alt="logo"
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
