import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { USER_SERVICES } from "services";

import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyBDvDZ28nHkN-hyhaholwp_Bz4AJ8PiO0s",
  authDomain: "by0.com",
  projectId: "by0-com",
  storageBucket: "by0-com.firebasestorage.app",
  messagingSenderId: "66294991867",
  appId: "1:66294991867:web:0303113acd8ebcc59f195e",
  measurementId: "G-7NFVZJ7EV4",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);
const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email,
      };

      let data = await USER_SERVICES.loginUser(DATA);

      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        toast("You are now logged in");
        return { code: 200, data: d };
      } else {
        toast(data?.message || "Error : Something went wrong!");
        return false;
      }
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithEmailPassword = async (email, password, reset) => {
  try {
    return signInWithEmailAndPassword(
      auth,
      email,
      email?.slice(0, 2) + password
    )
      .then(async (userCredential) => {
        if (auth.currentUser) {
          const user = auth.currentUser;

          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
            reset: reset,
          };
          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;

            toast("You are now logged in");
            return { code: 200, data: d };
          } else {
            toast(data.message);
            return false;
          }
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch(async (error) => {
        toast(errorSplit(error.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithCredentialResponse = async (response) => {
  if (response) {
    try {
      const cred = GoogleAuthProvider.credential(response.credential);
      await signInWithCredential(auth, cred);
      if (auth.currentUser) {
        const user = auth.currentUser;
        const DATA = {
          tokenID: user.accessToken,
          email: user.email,
        };

        let data = await USER_SERVICES.loginUser(DATA);
        if (data.code === 200) {
          let d = data.data;
          d["uid"] = DATA?.uid;
          toast("You are now logged in");
          return { code: 200, data: d };
        } else {
          for (const property in data.errors) {
            toast(
              data.errors[property]?.[0] || "Error : Something went wrong!"
            );
          }
          return false;
        }
      } else {
        toast("Error:  Login faild");
        return false;
      }
    } catch (err) {
      toast(errorSplit(err.message));
      return false;
    }
  } else {
    toast(errorSplit("Something went wrong try again"));
    return false;
  }
};

const signInWithUserEmailLink = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};
const signInWithUserEmailLinkVerify = (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        if (auth.currentUser) {
          const user = auth.currentUser;
          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
          };
          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;
            toast("You are now logged in");
            return { code: 200, data: d };
          } else {
            if (data.errors) {
              for (const property in data.errors) {
                toast(
                  data.errors[property]?.[0] || "Error : Something went wrong!"
                );
              }
            } else {
              toast(data.message);
            }
            return false;
          }
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } else {
    toast("Error:  Something went wrong");
    return false;
  }
};

const userLogout = (user) => {
  signOut(auth);
  if (user?.userId) {
    USER_SERVICES.logoutUser({ userId: user?.userId });
  }
};

const errorSplit = (error) => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

const sendForgotPasswordEmail = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast("An email with the link has been sent to " + email);
        return true;
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
  signInWithCredentialResponse,
  signInWithEmailPassword,
  sendForgotPasswordEmail,
};
