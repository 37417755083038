import Chatgpt from "pages/Chatgpt";
import AuthPage from "pages/AuthPage";
import ProfilePage from "pages/Profile";
import PrivacyPage from "pages/Privacy";
import { Route, Routes } from "react-router-dom";
import PasswordResetPage from "pages/PasswordResetPage";
import TermsPage from "pages/Terms";

export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Chatgpt />} />
    <Route path="/profile" element={<ProfilePage />} />
    <Route path="/privacy-policy" element={<PrivacyPage />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/callback" element={<AuthPage />} />
    <Route path="password">
      <Route path="reset/:actionCode" element={<PasswordResetPage />} />
    </Route>
  </Routes>
);
