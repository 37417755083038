import axios from "axios";
import { GET_USER_AUTH_TOKEN } from "../common/auth";
import { API_URL } from "../common/constants";

/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */

export function apiCall(url, data, signal = null) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: GET_USER_AUTH_TOKEN(),
  };

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
        signal: signal,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function apiCallMultipart(url, data, onUploadProgress) {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: GET_USER_AUTH_TOKEN(),
  };

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
        onUploadProgress,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
