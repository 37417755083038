import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormControl } from "@mui/material";
import { auth, sendForgotPasswordEmail } from "../../../firebase";
import { GoogleIcon, Outlook } from "../../../icons";
import FullPageLoader from "components/Common/FullPageLoader";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { isValidEmail } from "../../../common/utils";

const ForgotPassword = (props) => {
  const { passwordLogin, setPasswordLogin, email, setEmail } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);

  const passwordRequest = async () => {
    setLoader(true);
    if (!email || (email && !isValidEmail(email))) {
      toast("Enter your registered email address");
    } else {
      const data = await sendForgotPasswordEmail(email);
      if (data) {
        setPasswordLogin(4);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (error) {
      toast(error);
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageLoader fixed={true} />}
      <div className="userLogin-email custom-form">
        {passwordLogin === 4 ? (
          <>
            <div className="userLogin-email-msg">
              An email with the link has been sent to {email}
            </div>
            <div className="userLogin-line"></div>
            <div className="userLogin-email-msg">Check your email</div>

            <div className="userLogin-social mt-20" style={{ gap: "20px" }}>
              <a
                href="https://mail.google.com/"
                target="_blank"
                rel="noreferrer"
                className="userLogin-social-btn"
              >
                <GoogleIcon /> Gmail
              </a>
              <a
                href="https://outlook.live.com/mail/0/"
                target="_blank"
                rel="noreferrer"
                className="userLogin-social-btn mt-0"
              >
                <Outlook /> Outlook
              </a>
            </div>

            <div
              className="userLogin-email-link mt-20"
              onClick={() => {
                setPasswordLogin(3);
              }}
            >
              Did not get the link?
            </div>
          </>
        ) : (
          <>
            <FormControl className="custom-form-group">
              <label className="custom-form-label" for="login-email">
                Email
              </label>
              <input
                id="login-email"
                placeholder="your@email.com"
                className="custom-form-input"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>

            <button
              type="button"
              className="userLogin-email-btn mt-10"
              onClick={passwordRequest}
              disabled={loader || loading}
            >
              Continue
            </button>
            <div className="userLogin-email-policy mt-20">
              Clicking continue confirms you accept 6ix's{" "}
              <Link to="/privacy-policy" className="text-link" target="_blank">
                Privacy Notice.
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ForgotPassword;
