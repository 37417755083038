import { Link } from "react-router-dom";
import PasswordReset from "components/Account/Password/PasswordReset";
import { ArrowLeft } from "icons";

const PasswordResetPage = () => {
  return (
    <>
      <div
        style={{
          flex: "1 1 0",
          height: "100%;",
          overflowY: "auto",
          display: "flex",
        }}
      >
        <div className="sixAIPopup sixAIPopup-xs userLogin">
          <div
            className="sixAIPopup-inner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="sixAIPopup-header">
              <div className="sixAIPopup-header-heading">
                Create New 4-digit PIN
              </div>
            </div>

            <div className="sixAIPopup-content">
              <PasswordReset />

              <div className="userLogin-email-footer">
                <Link
                  to="/"
                  className="userLogin-email-link userLogin-email-footer-col"
                >
                  <ArrowLeft /> Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetPage;
