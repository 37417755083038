import { apiCall } from "./servicesCall";

export const TOPIC_SERVICES = {
  getList(data) {
    return apiCall("topic/list", data);
  },
  getShareList(data) {
    return apiCall("topic/share/list", data);
  },
  shareDelete(data) {
    return apiCall("topic/share/delete", data);
  },
  editTopic(data) {
    return apiCall("topic/edit", data);
  },
  deleteTopic(data) {
    return apiCall("topic/delete", data);
  },
  clearTopic(data) {
    return apiCall("topic/clear", data);
  },
  shareClear(data) {
    return apiCall("topic/share/clear", data);
  },
  share(data) {
    return apiCall("topic/share", data);
  },
};
