import { apiCall } from "./servicesCall";

export const USER_SERVICES = {
  loginUser(data) {
    return apiCall("user/login", data);
  },
  loginEmail(data) {
    return apiCall("/user/login/email", data);
  },

  logoutUser(data) {
    return apiCall("user/logout", data);
  },

  getUser(data) {
    return apiCall("user/current", data);
  },
  getProfile(data) {
    return apiCall("user/profile", data);
  },
  updateProfile(data) {
    return apiCall("user/profile/update", data);
  },
  updatePassword(data) {
    return apiCall("user/password/update", data);
  },
};
