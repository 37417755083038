const Privacy = () => {
  return (
    <div className="sixai-page-privacy-inner">
      <h1 className="sixai-page-privacy-heading">Privacy Policy</h1>

      <div class="sixai-page-privacy-text">
        <b>Last Updated: Feb 05, 2025</b>
      </div>
      <div class="sixai-page-privacy-text">
        Welcome to By0! Your privacy is important to us. This Privacy Policy
        explains how we collect, use, and protect your information when you use
        our web application and iOS app (collectively, the "Service"). By using
        By0, you agree to the terms outlined in this policy.
      </div>
      <br />
      <br />

      <h4 className="sixai-page-privacy-heading">1. Information We Collect</h4>
      <div class="sixai-page-privacy-text">
        We may collect the following types of information:
      </div>
      <div class="sixai-page-privacy-text">
        <b>a. Personal Information</b>
        <br />
        <ul>
          <li>
            When you create an account, we may collect your name, email address,
            and other required details.
          </li>
          <li>
            If you contact us for support, we may collect your contact
            information and any details you provide.
          </li>
        </ul>
      </div>

      <div class="sixai-page-privacy-text">
        <b>b. Usage Data</b>
        <br />
        <ul>
          <li>
            We collect information about how you use our Service, including
            interactions with the AI, app settings, and logs.
          </li>
          <li>
            We collect information about how you use our Service, including
            interactions with the AI, app settings, and logs.
          </li>
        </ul>
      </div>
      <div class="sixai-page-privacy-text">
        <b>c. Cookies and Tracking Technologies</b>
        <br />
        <ul>
          <li>
            We use cookies and similar technologies to enhance user experience
            and track usage patterns.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">
        2. How We Use Your Information
      </h4>
      <div class="sixai-page-privacy-text">
        We use your information for the following purposes:
      </div>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>To provide and improve the Service.</li>
          <li>To personalize user experience.</li>
          <li>To personalize user experience.</li>
          <li>
            To communicate with you regarding updates, support, and other
            relevant information.
          </li>
          <li>To ensure compliance with our terms and policies.</li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">
        3. How We Share Your Information
      </h4>
      <div class="sixai-page-privacy-text">
        We do not sell your personal information. However, we may share
        information in the following cases:
      </div>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            With service providers who assist us in operating the Service.
          </li>
          <li>When required by law or legal processes.</li>
          <li>
            In connection with a business transfer (e.g., merger, acquisition).
          </li>
          <li>With your consent or at your direction.</li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">4. Data Security</h4>
      <div class="sixai-page-privacy-text">
        We implement industry-standard security measures to protect your data.
        However, no method of transmission or storage is 100% secure. Please use
        our Service with caution.
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">5. Your Rights and Choices</h4>
      <div class="sixai-page-privacy-text">
        Depending on your location, you may have rights to:
      </div>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>Access, update, or delete your personal information.</li>
          <li>Opt out of certain data collection.</li>
        </ul>
      </div>
      <div class="sixai-page-privacy-text">
        To exercise these rights, contact us at support@by0.com
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">6. Children's Privacy</h4>
      <div class="sixai-page-privacy-text">
        By0 is not intended for children under 13. We do not knowingly collect
        data from minors. If we learn of such collection, we will take
        appropriate action to delete the data.
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">7. Third-Party Services</h4>
      <div class="sixai-page-privacy-text">
        Our Service may contain links to third-party services. We are not
        responsible for their privacy practices. Please review their policies
        before providing information.
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">8. Changes to This Policy</h4>
      <div class="sixai-page-privacy-text">
        We may update this Privacy Policy periodically. Any changes will be
        posted on this page with an updated effective date. Continued use of the
        Service constitutes acceptance of the changes.
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">9. Contact Us</h4>
      <div class="sixai-page-privacy-text">
        If you have any questions about this Privacy Policy, please contact us
        at support@by0.com
      </div>

      <div class="sixai-page-privacy-text">
        By0 licenses software to publicly traded issuers of securities
        (“Issuers” and each an “Issuer”) which can be used by Issuers to
        communicate with current and prospective investors (“Investors” and each
        an “Investor”) on the online platform maintained by By0 at www.by0.com
        (the “Platform”). Each Issuer is solely responsible for all content
        relating to the Issuer on the by0 Platform, including without limitation
        all investor summits presented by the Issuer and all summary information
        about the Issuer on the by0 Platform.
      </div>
    </div>
  );
};
export default Privacy;
