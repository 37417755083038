import { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { userLogout } from "../firebase";

const ENCRYPT_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const COOKIES_DOMAIN = process.env.REACT_APP_VERCEL_ENV
  ? process.env.REACT_APP_COOKIES_DOMAIN
  : null;

const ENCRYPT_DATA = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString();
  return encrypted;
};

const DECRYPT_DATA = (data) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    return;
  }
};

export const GET_USER_AUTH_TOKEN = () => {
  const cookies = new Cookies();
  const token = cookies.get("by0_au");
  return token ? `Bearer ${token}` : "";
};

export function SESSION_AUTH() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [translateCookies, setTranslateCookies] = useState(() =>
    cookies.get("googtrans")
  );
  const [isUserReinitialize, setIsUserReinitialize] = useState("");
  const [loginSession, setLoginSession, removeLoginSession] = useCookies([
    "by0_u",
  ]);

  const LOGOUT_USER_SESSION = (user = "") => {
    userLogout(user);
    removeLoginSession("by0_u", {
      path: "/",
      domain: COOKIES_DOMAIN,
    });
    removeLoginSession("by0_au", {
      path: "/",
      domain: COOKIES_DOMAIN,
    });
    for (const cookie in cookies.getAll()) {
      cookies.remove(cookie, {
        path: "/",
        domain: COOKIES_DOMAIN,
      });
    }
    setTimeout(function () {
      navigate(0);
    }, 100);
  };

  const GET_USER_SESSION = () => {
    if (loginSession?.by0_u) {
      let data = DECRYPT_DATA(loginSession?.by0_u);
      try {
        data = data ? JSON.parse(data) : "";
        if (data?.userId) {
          return data;
        } else {
          LOGOUT_USER_SESSION();
          return "";
        }
      } catch (err) {
        LOGOUT_USER_SESSION();
        return "";
      }
    }
    return "";
  };

  const [isUserSession, setIsuserSession] = useState(() => GET_USER_SESSION());

  const SET_USER_SESSION = (user) => {
    let data = ENCRYPT_DATA(JSON.stringify(user));
    var expireDate = new Date();
    expireDate.setHours(0, 0, 0, 0);
    expireDate.setFullYear(expireDate.getFullYear() + 1);

    setLoginSession("by0_u", data, {
      sameSite: "strict",
      secure: process.env.REACT_APP_VERCEL_ENV ? true : false,
      expires: expireDate,
      path: "/",
      domain: COOKIES_DOMAIN,
    });
    setLoginSession("by0_au", user.authToken, {
      sameSite: "strict",
      secure: process.env.REACT_APP_VERCEL_ENV ? true : false,
      expires: expireDate,
      path: "/",
      domain: COOKIES_DOMAIN,
    });
  };

  const UPDATE_USER_SESSION = (data = false) => {
    if (data) {
      let user = GET_USER_SESSION();
      for (let key in data) {
        user[key] = data[key];
      }
      SET_USER_SESSION(user);
    }
  };

  useEffect(() => {
    if (loginSession?.by0_u) {
      let data = DECRYPT_DATA(loginSession?.by0_u);
      try {
        setIsuserSession(data ? JSON.parse(data) : "");
      } catch (err) {
        setIsuserSession("");
      }
    } else {
      setIsuserSession("");
    }
    setTranslateCookies(loginSession?.googtrans || "");
  }, [loginSession]);

  return {
    isUserSession,
    SET_USER_SESSION,
    UPDATE_USER_SESSION,
    LOGOUT_USER_SESSION,
    isUserReinitialize,
    setIsUserReinitialize,
    translateCookies,
    setTranslateCookies,
  };
}
