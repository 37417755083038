const Privacy = () => {
  return (
    <div className="sixai-page-privacy-inner">
      <h1 className="sixai-page-privacy-heading">Terms & Conditions</h1>

      <div class="sixai-page-privacy-text">
        <b>Last Updated: Feb 05, 2025</b>
      </div>
      <div class="sixai-page-privacy-text">
        Welcome to By0! These Terms & Conditions ("Terms") govern your use of
        our web application and iOS app (collectively, the "Service"). By
        accessing or using By0, you agree to these Terms. If you do not agree,
        please refrain from using the Service.
      </div>
      <br />
      <br />

      <h4 className="sixai-page-privacy-heading">1. Use of the Service</h4>

      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            By0 provides an AI-powered platform for users to ask and receive
            responses.
          </li>
          <li>You must be at least 13 years old to use the Service.</li>
          <li>
            You agree not to misuse the Service, including attempting to disrupt
            or harm the platform.
          </li>
        </ul>
      </div>

      <br />
      <h4 className="sixai-page-privacy-heading">2. User Accounts</h4>

      <div class="sixai-page-privacy-text">
        <ul>
          <li>You may need to create an account to access certain features.</li>
          <li>
            You are responsible for maintaining the security of your account
            credentials.
          </li>
          <li>
            By0 reserves the right to suspend or terminate accounts that violate
            these Terms.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">3. Content and Usage</h4>

      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            You retain ownership of any content you submit but grant By0 a
            non-exclusive license to process and display it as necessary for the
            Service.
          </li>
          <li>
            By0 is not responsible for the accuracy of AI-generated responses.
          </li>
          <li>
            You agree not to use the Service for illegal, harmful, or unethical
            activities.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">4. Privacy</h4>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>Your use of By0 is also governed by our Privacy Policy.</li>
          <li>
            We collect and process data as described in our Privacy Policy.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">5. Intellectual Property</h4>

      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            All trademarks, logos, and software related to By0 remain the
            property of their respective owners.
          </li>
          <li>
            You may not copy, modify, distribute, or reverse-engineer any part
            of the Service without authorization.
          </li>
        </ul>
      </div>

      <br />
      <h4 className="sixai-page-privacy-heading">6. Limitation of Liability</h4>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>By0 is provided "as is" without warranties of any kind.</li>
          <li>
            We are not liable for any direct, indirect, incidental, or
            consequential damages arising from the use of the Service.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">7. Termination</h4>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            We reserve the right to suspend or terminate your access to the
            Service for violations of these Terms
          </li>
          <li>You may stop using the Service at any time</li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">8. Changes to These Terms</h4>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>By0 may update these Terms periodically.</li>
          <li>
            Continued use of the Service after changes signifies acceptance of
            the updated Terms.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">9. Governing Law</h4>
      <div class="sixai-page-privacy-text">
        <ul>
          <li>
            These Terms are governed by the laws of the United States of
            America.
          </li>
          <li>
            Any disputes will be resolved in the courts of the United States of
            America.
          </li>
        </ul>
      </div>
      <br />
      <h4 className="sixai-page-privacy-heading">10. Contact Us</h4>
      <div class="sixai-page-privacy-text">
        For questions about these Terms, please contact us at support@by0.com
      </div>
    </div>
  );
};
export default Privacy;
