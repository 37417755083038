import { apiCall } from "./servicesCall";

export const CONVERSATION_SERVICES = {
  getList(data) {
    return apiCall("conversation/list", data);
  },
  create(data, signal) {
    return apiCall("conversation", data, signal);
  },
  update(data) {
    return apiCall("conversation/update", data);
  },
};
