import { CircularProgress } from "@mui/material";

const FullPageLoader = (props) => {
  return (
    <div
      className={`sixai-loader ${!props?.normal ? "sixai-loader-fixed" : ""}  ${
        props?.dark ? "sixai-loader-dark" : ""
      }`}
    >
      <CircularProgress size={90} thickness={3.8} color="inherit" />
    </div>
  );
};

export default FullPageLoader;
