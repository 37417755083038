import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { SESSION_AUTH } from "common/auth";
import { useNavigate, Link } from "react-router-dom";
import HeaderUsers from "components/Header/HeaderUser";
import HeaderLogo from "components/Header/HeaderLogo";
import { MenuIcon } from "icons";
import Profile from "components/Account/Profile";

const ProfilePage = () => {
  const { isUserSession } = SESSION_AUTH();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserSession) {
      navigate("/");
    }
  }, [isUserSession, navigate]);

  return (
    <>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme={"dark"}
        position="bottom-left"
      />

      <div className="sixai">
        <div className="sixai-chats-modals-header justify-content-center">
          <div className="sixai-chats-modals-header-left">
            <Link to="/" className="sixai-chats-modals-header-menuButton">
              <MenuIcon />
            </Link>
            <div className="sixai-chats-modals-header-lineV"></div>

            <HeaderLogo />
          </div>
          <div className="sixai-users-features ">
            <div className="sixai-users-features-item">
              <HeaderUsers />
            </div>
          </div>
        </div>

        <div className="sixai-content sixai-page-profile">
          <Profile />
        </div>
      </div>
    </>
  );
};
export default ProfilePage;
