import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GppGoodOutlined, ShieldOutlined } from "@mui/icons-material";
import { Button, FormControl } from "@mui/material";
import { CheckMark } from "icons";
import PasswordsProtect from "../Password/PasswordsProtect";
import { USER_SERVICES } from "services";
import { SESSION_AUTH } from "common/auth";
import FullPageLoader from "components/Common/FullPageLoader";
import "./style.scss";

export const Profile = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [pinRequest, setPinRequest] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    try {
      setLoading(true);
      USER_SERVICES.getProfile({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then((data) => {
          if (data.code === 200) {
            setFormValues(data.data);
            setEmail(data?.data?.email);
            setName(data?.data?.name || "");
            setHasPassword(data?.data?.hasPassword);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
          }
        })
        .catch((error) => {
          toast(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast(error.message);
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  const saveProfile = async () => {
    if (!name) {
      toast("Please fill your name");
      return false;
    }

    if (!userData) {
      return;
    }

    const profileData = new FormData();
    profileData.append("name", name?.trim());
    profileData.append("userId", userData.userId);

    try {
      setLoading(true);
      USER_SERVICES.updateProfile(profileData)
        .then((data) => {
          if (data.code === 200) {
            navigate("/");
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            toast(data.message);
          }
        })
        .catch((e) => {
          toast("Error Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      toast("Error Something went wrong");
    }
  };

  return (
    <React.Fragment>
      {loading && <FullPageLoader />}
      {formValues ? (
        <div className="sixai-page-profile-inner">
          <form
            name="userprofileform"
            id="userprofileform"
            className="custom-form"
          >
            <FormControl className="custom-form-group">
              <label htmlFor="email" className="custom-form-label big-label">
                Email
              </label>
              <input
                id="email"
                name="email"
                disabled
                placeholder="Enter Your EMail"
                className="custom-form-input"
                value={email}
                autoComplete="none"
              />
            </FormControl>
            <FormControl className="custom-form-group">
              <label htmlFor="name" className="custom-form-label big-label">
                Name
              </label>
              <input
                id="name"
                name="name"
                placeholder="Enter Your Name"
                className="custom-form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="none"
              />
            </FormControl>

            <FormControl className="custom-form-group mt-10">
              <label className="custom-form-label big-label sixai-page-profile-pin-label">
                {hasPassword ? (
                  <>
                    <CheckMark />
                    Your account is protected by a PIN code
                  </>
                ) : (
                  <>Protect your account with a PIN code</>
                )}
              </label>
              <div className="mb-10 mt-10">
                <Button
                  color="secondary"
                  variant="outlined"
                  className="sixai-btn sixai-btn-secondary "
                  type="button"
                  startIcon={
                    hasPassword ? <GppGoodOutlined /> : <ShieldOutlined />
                  }
                  onClick={() => setPinRequest(true)}
                >
                  {hasPassword ? "Change PIN" : "Add PIN"}
                </Button>
              </div>
            </FormControl>
          </form>

          <div className="text-center">
            <Button
              color="primary"
              variant="outlined"
              className="sixai-btn sixai-btn-primary sixai-page-profile-btn"
              type="button"
              onClick={() => {
                saveProfile();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}

      {pinRequest && (
        <PasswordsProtect
          hasPassword={hasPassword}
          onClose={() => {
            setDataFetched(false);
            setPinRequest(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Profile;
