import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { SESSION_AUTH } from "common/auth";
import { Link } from "react-router-dom";
import HeaderUsers from "components/Header/HeaderUser";
import HeaderLogo from "components/Header/HeaderLogo";
import { MenuIcon, UserLogin } from "icons";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import Terms from "components/Terms";

const TermsPage = () => {
  const { isUserSession } = SESSION_AUTH();

  const [loginRequest, setLoginRequest] = useState(false);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };
  return (
    <>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme={"dark"}
        position="bottom-left"
      />

      <div className="sixai">
        <div className="sixai-chats-modals-header justify-content-center">
          <div className="sixai-chats-modals-header-left">
            <Link to="/" className="sixai-chats-modals-header-menuButton">
              <MenuIcon />
            </Link>
            <div className="sixai-chats-modals-header-lineV"></div>

            <HeaderLogo />
          </div>
          <div className="sixai-users-features ">
            <div className="sixai-users-features-item">
              {isUserSession ? (
                <HeaderUsers />
              ) : (
                <div
                  className="sixai-users-features-item-btn"
                  onClick={handleLogin}
                >
                  <UserLogin />
                  <div className="sixai-users-features-item-btn-text">
                    LOGIN
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="sixai-content sixai-page-privacy">
          <Terms />
        </div>
      </div>

      {!isUserSession && loginRequest ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}
    </>
  );
};
export default TermsPage;
