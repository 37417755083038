import { useState } from "react";
import { FormControl } from "@mui/material";
import { Cross } from "icons";
import PinBox from "./PinBox";
import { toast } from "react-toastify";
import { SESSION_AUTH } from "common/auth";
import { USER_SERVICES } from "services";
import FullPageLoader from "components/Common/FullPageLoader";

const PasswordsProtect = (props) => {
  const { onClose, hasPassword } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loader, setLoader] = useState(false);
  const [pin, setPin] = useState("");

  const savePassword = async () => {
    if (!pin) {
      toast("Enter new PIN");
    } else if (pin && pin?.length < 4) {
      toast("Enter 4-digit PIN");
    } else {
      setLoader(true);
      USER_SERVICES.updatePassword({
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        password: pin,
      })
        .then((data) => {
          if (data.code === 200) {
            toast(data.message);
            return onClose();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
          }
        })
        .catch((err) => {
          toast(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  return (
    <div
      align="center"
      direction="column"
      className="sixAIPopup sixAIPopup-xs userLogin"
    >
      {loader ? (
        <FullPageLoader fixed={true} />
      ) : (
        <div
          className="sixAIPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="sixAIPopup-close" onClick={onClose}>
            <Cross />
          </div>

          <div className="sixAIPopup-header">
            <div className="sixAIPopup-header-heading">
              {hasPassword ? "Change 4-digit PIN" : "Create New 4-digit PIN"}
            </div>
          </div>

          <div className="sixAIPopup-content">
            <div className="userLogin-email custom-form mt-20">
              <FormControl className="custom-form-group">
                <PinBox updatePin={setPin} />
              </FormControl>
              <button
                type="button"
                className="userLogin-email-btn mt-20"
                onClick={savePassword}
                disabled={loader}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordsProtect;
