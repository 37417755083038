import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormControl } from "@mui/material";
import { auth } from "../../../firebase";
import FullPageLoader from "components/Common/FullPageLoader";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../common/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { USER_SERVICES } from "services";
import { SESSION_AUTH } from "../../../common/auth";

const LoginWithEmail = (props) => {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin, setPasswordLogin, email, setEmail } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);

  const loginEmail = async () => {
    if (!email || (email && !isValidEmail(email))) {
      toast("Enter your email address");
    } else {
      setLoader(true);
      USER_SERVICES.loginEmail({ email: email })
        .then((data) => {
          if (data?.code === 201) {
            setPasswordLogin(2);
          } else if (data?.code === 200) {
            SET_USER_SESSION(data?.data);
            if (handleLogin) handleLogin();
          } else {
            toast(data.message);
          }
        })
        .catch((error) => {
          toast(error.message);
        })
        .finally(() => setLoader(false));
    }
  };

  useEffect(() => {
    if (error) {
      toast(error);
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageLoader fixed={true} />}

      <div className="userLogin-email custom-form">
        <FormControl className="custom-form-group">
          <label className="custom-form-label" htmlFor="login-email">
            Email
          </label>
          <input
            id="login-email"
            placeholder="your@email.com"
            className="custom-form-input"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="email"
          />
        </FormControl>

        <button
          type="button"
          className="userLogin-email-btn mt-20"
          onClick={loginEmail}
          disabled={loader || loading}
        >
          Continue
        </button>

        <div className="userLogin-email-policy mt-20">
          Clicking continue confirms you accept 6ix's{" "}
          <Link to="/privacy-policy" className="text-link" target="_blank">
            Privacy Notice.
          </Link>
        </div>
      </div>
    </>
  );
};
export default LoginWithEmail;
