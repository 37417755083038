import { useState, useEffect } from "react";
import LoginWithPassword from "./LoginWithPassword";
import LoginWithEmail from "./LoginWithEmail";
import ForgotPassword from "./ForgotPassword";
import { SESSION_AUTH } from "common/auth";
import { Cross } from "icons";
import "./Login.scss";

export function LoginPopup(props) {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, dropOut } = props;

  const [passwordLogin, setPasswordLogin] = useState(0);

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isUserSession) {
      handleLogin();
    }
  }, [isUserSession, handleLogin]);

  return (
    <>
      <div
        className="sixAIPopup sixAIPopup-xs userLogin"
        onClick={() => {
          if (dropOut) {
            handleLogin();
          }
        }}
      >
        <div
          className="sixAIPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="sixAIPopup-close" onClick={handleLogin}>
            <Cross />
          </div>

          {email && passwordLogin === 2 ? (
            <>
              <div className="sixAIPopup-header">
                <div className="sixAIPopup-header-heading">
                  Enter your 4-digit PIN
                </div>
                <div className="sixAIPopup-header-desc"></div>
              </div>
              <div className="sixAIPopup-content">
                <LoginWithPassword
                  setPasswordLogin={setPasswordLogin}
                  handleLogin={handleLogin}
                  setEmail={setEmail}
                  email={email}
                />
              </div>
            </>
          ) : email && (passwordLogin === 3 || passwordLogin === 4) ? (
            <>
              <div className="sixAIPopup-header">
                <div className="sixAIPopup-header-heading">Reset PIN</div>
                <div className="sixAIPopup-header-desc">
                  {passwordLogin === 3 ? (
                    <> We will send you a link to reset your PIN </>
                  ) : null}
                </div>
                <div className="sixAIPopup-header-desc"></div>
              </div>
              <div className="sixAIPopup-content">
                <ForgotPassword
                  passwordLogin={passwordLogin}
                  email={email}
                  setEmail={setEmail}
                  setPasswordLogin={setPasswordLogin}
                />
              </div>
            </>
          ) : (
            <>
              <div className="sixAIPopup-header">
                <div className="sixAIPopup-header-heading">
                  Enter your email
                </div>
                <div className="sixAIPopup-header-desc"></div>
              </div>
              <div className="sixAIPopup-content">
                <LoginWithEmail
                  setPasswordLogin={setPasswordLogin}
                  handleLogin={handleLogin}
                  setEmail={setEmail}
                  email={email}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
